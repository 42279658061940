import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['navToggle', 'nav'];

  connect() {
    this.navToggleTarget.classList.remove('u-hidden');
    this.navTarget.classList.add('c-nav--hidden-sm');
  }

  toggleNav(event) {
    event.preventDefault();
    this.navTarget.classList.toggle('c-nav--hidden-sm');
  }
}
